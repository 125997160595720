exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-brief-1-js": () => import("./../../../src/pages/brief1.js" /* webpackChunkName: "component---src-pages-brief-1-js" */),
  "component---src-pages-brief-2-js": () => import("./../../../src/pages/brief2.js" /* webpackChunkName: "component---src-pages-brief-2-js" */),
  "component---src-pages-brief-3-js": () => import("./../../../src/pages/brief3.js" /* webpackChunkName: "component---src-pages-brief-3-js" */),
  "component---src-pages-brief-4-js": () => import("./../../../src/pages/brief4.js" /* webpackChunkName: "component---src-pages-brief-4-js" */),
  "component---src-pages-brief-5-js": () => import("./../../../src/pages/brief5.js" /* webpackChunkName: "component---src-pages-brief-5-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

